import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/article-page-layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Intro = makeShortcode("Intro");
const Body = makeShortcode("Body");
const Outro = makeShortcode("Outro");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Intro mdxType="Intro">
      <p>{`エルデンリングはもう3回、一からキャラクターを作ってクリアしたほどにプレイした。このゲームは、ストーリーはあるものの、その詳細については明確に語られず、ボスを倒した後に「これって誰だったの？」ってなることもしばしば。`}</p>
      <p>{`その背景を知るためにはアイテムのテキストに書いてあるストーリーの断片みたいなものをつなぎ合わせる必要があり、それをまとめてたらだいぶボリュームが出てきたので、それをまとめたのがコレです。`}</p>
      <p>{`なお、本記事の内容にはゲーム中盤〜後半のネタバレを含むので、ゲームをプレイしながらストーリーも楽しみたいという方は、一度クリアなりしてから読むことをおすすめします。`}</p>
      <p>{`今後もこういうのを書くのかは未知ですが、ひとまずこの記事はモーゴットについて。`}</p>
    </Intro>
    <Body mdxType="Body">
      <h2 {...{
        "id": "モーゴットの第一印象"
      }}>{`モーゴットの第一印象`}</h2>
      <p>{`このゲームをプレイした人の多くは、ゲームの序盤にストームヴィル城に到達し、そこで何の脈略もないままマルギットというボスが登場し、ボコボコにされて苦しむと思われます。このマルギットを倒すと、マルギットは「お前覚えたぞ夜道に気をつけるんだな」みたいな捨て台詞を残してどこかにいってしまう。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/eed108a0-3721-4f86-acad-e0b357d6af02_20220430030249_1.jpg?w=1116&h=627",
          "alt": null
        }}></img></p>
      <p>{`そしてそこからだいぶ話を進めていくと王都ローデイルというところに来ることになる。その城の王は「姿なき王、祝福のモーゴット」と呼ばれるデミゴッドの一人であるというヒントはそれまでに与えられるも、その姿はそこまでのストーリーの中で、全く登場しない。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/345dd9b0-6f3f-4e56-b998-99a01b0bc3ec_20220501024044_1.jpg?w=1116&h=627",
          "alt": null
        }}></img></p>
      <p>{`ローデイルの一番奥まで行くとなにやらマルギットっぽいのが出てきて、他のデミゴッドらを名指しして「お前らは裏切り者だ」みたいな事を言ったと思ったら戦闘。その行動はマルギットととても似ていて、倒したあとも、あれ？これってマルギットだったのかな？違うのかな？みたいな余韻を残して、基本そこでモーゴットは終了という感じかと思われます。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/fe0feb91-df04-44d1-af12-7509c0373171_ss_2022-05-01_21.06.17.png?w=1116&h=626",
          "alt": null
        }}></img></p>
      <p>{`そんなのがモーゴット。`}</p>
      <h2 {...{
        "id": "マリカとゴッドフレイの子"
      }}>{`マリカとゴッドフレイの子`}</h2>
      <p>{`まずこのモーゴット、デミゴッドも色々いる中で、父がゴッドフレイ、母がマリカ……なはず。理由としてはまず、エンヤより`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/3b25592e-9439-4bec-84e8-46ce05b3e7d3_20220501023944_1.jpg?w=1116&h=627",
          "alt": null
        }}></img></p>
      <blockquote>
        <p parentName="blockquote">{`デミゴッドたちは、皆が女王マリカの直接の子`}</p>
      </blockquote>
      <p>{`と語られるので、デミゴッドはマリカの子であることが分かる。`}</p>
      <p>{`そして、アイテム「モーゴットの大ルーン」の説明には`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/e9870fed-e407-4b67-9ad3-e2526dd79623_20220429001820_1.jpg?w=1116&h=627",
          "alt": null
        }}></img></p>
      <p>{`以下のようにあり、`}</p>
      <blockquote>
        <p parentName="blockquote">{`その大ルーンは、幹を持つ要の輪であり`}<br parentName="p"></br>{`
`}{`それは二つの事実を示している`}</p>
        <p parentName="blockquote">{`忌み王が、黄金の一族として生まれたこと`}<br parentName="p"></br>{`
`}{`そして、確かにローデイルの王であったことを`}</p>
      </blockquote>
      <p>{`モーゴットが「黄金の一族」であることが分かる。`}</p>
      <p>{`アイテム「ゴッドフレイの王斧」`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/54fb3d56-bcc6-4dbc-a030-5c0157f19b63_20220501004713_1.jpg?w=1116&h=627",
          "alt": null
        }}></img></p>
      <p>{`に以下のようにあったり、`}</p>
      <blockquote>
        <p parentName="blockquote">{`褪せ人を率いた長征の戦いにより、半壊している。`}</p>
        <p parentName="blockquote">{`ゴッドフレイが王として振舞う誓いであり`}<br parentName="p"></br>{`
`}{`後には、黄金の一族の象徴ともなった武器`}<br parentName="p"></br>{`
`}{`かつて、力こそが王の故であった`}</p>
      </blockquote>
      <p>{`ほか、「黄金の一族」と言われる家系はゴッドフレイとマリカの子孫達を示す感じっぽいので、これらのことから、モーゴットのとーちゃんはゴッドフレイ、かーちゃんはマリカであると思われる。`}</p>
      <h2 {...{
        "id": "忌み子であるモーゴット"
      }}>{`忌み子であるモーゴット`}</h2>
      <p>{`そして、モーゴットは忌み子である。`}</p>
      <p>{`このエルデンリングの世界で「忌み子」というのは、ツノが生えて生まれてきちゃった子供のことを指す。「忌み」ってなんだろうって改めて調べてみると、`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://ja.wikipedia.org/wiki/%E5%BF%8C%E3%81%BF",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Wikipedia: 忌み`}</a></li>
      </ul>
      <p>{`要するに穢れた存在であるという感じっぽい。黄金律の世界において、不吉な、穢らわしい呪いのようなものに侵されて産まれてきてしまった子みたいな感じだろうか。で、モーゴットは見ての通り角がぴょんぴょん生えているので、忌み子として産まれてきてしまった存在ということ。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/f74d9663-ea2b-4d7c-a3a7-56f4918dfd58_morgott.png?w=1116&h=622",
          "alt": null
        }}></img></p>
      <p>{`この忌み子、通常は角を全て切られてしまうらしい。それは以下の「忌み水子」というアイテムのテキストや絵からも分かる。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/2b8cc5bf-165d-4f7e-8136-b64467083378_20220428231542_1.jpg?w=1116&h=627",
          "alt": null
        }}></img></p>
      <blockquote>
        <p parentName="blockquote">{`忌み赤子は、その醜い角をすべて切られ`}<br parentName="p"></br>{`
`}{`大抵はそのまま死んでしまう`}<br parentName="p"></br>{`
`}{`これは、その供養の像である`}</p>
        <p parentName="blockquote">{`どうか、私を恨み、呪わないでください`}</p>
      </blockquote>
      <p>{`こわい。`}<br parentName="p"></br>{`
`}{`この角を切られた忌み子は、ローデイル周辺はストームヴィルでちょいちょい見受けられる。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/b8fa1423-21d1-4d11-ab2f-2d0cbc5e35c4_20220501015411_1.jpg?w=1116&h=627",
          "alt": null
        }}></img></p>
      <p>{`細かく話すと脱線するので突っ込んでは書かないが、この人の着てるヤツもそれ`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/f699f5ca-403f-4dbe-bec3-7afb839f20d4_20220428225123_1.jpg?w=1116&h=627",
          "alt": null
        }}></img></p>
      <p>{`しかし、王家に産まれた忌み子の扱いは違う。`}<br parentName="p"></br>{`
`}{`以下は「王家の忌み水子」。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/c2672b19-00e0-4a19-9399-012d3134d0d6_20220428230746_1.jpg?w=1116&h=627",
          "alt": null
        }}></img></p>
      <blockquote>
        <p parentName="blockquote">{`王家の忌み水子は、角を切られることはない`}<br parentName="p"></br>{`
`}{`その替り、誰にも知られず、地下に捨てられ`}<br parentName="p"></br>{`
`}{`永遠に幽閉される`}</p>
        <p parentName="blockquote">{`そしてひっそりと、供養の像が作られる`}</p>
      </blockquote>
      <p>{`かわいそう。`}</p>
      <p>{`つまり以下、ローデイルの地下にいるやたら強い敵はローデイル王家に産まれた忌み子であると分かる。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/ca4c19ed-a5b7-47d4-93c9-90ec1f8f3167_20220501004402_1.jpg?w=1116&h=627",
          "alt": null
        }}></img></p>
      <p>{`と、そんなわけで、モーゴットも見ての通り角が生えており、忌み子。`}</p>
      <h2 {...{
        "id": "忌み子であり黄金の一族であるモーゴット"
      }}>{`忌み子であり黄金の一族であるモーゴット`}</h2>
      <p>{`そんな風に、忌み子の扱いが酷い狭間の地なのだけれども、それでもモーゴットは黄金の一族の使命を全うしようとする。黄金の一族の使命とは、黄金樹を守ること。`}</p>
      <p>{`ただ、黄金樹を守ると言っても、別に誰かがモーゴットに命令したわけではないとは思われる。ただ父ゴッドフレイと母マリカが治めていたローデイルの跡継ぎとして、黄金樹を守りたかったんだろうと想像される。`}</p>
      <p>{`ところが自身は黄金の一族であると同時に、呪われ穢れた存在である忌み子である。そんなわけで、一切姿を表さず、「姿なき王」と周りには言われながら、ローデイルの王をやっていたのがモーゴット。`}</p>
      <p>{`そして、エルデの王を目指すものを片っ端からボコボコにしに行くときに名乗っているのが「忌み鬼マルギット」という名前であると思われる。ローデイルの碑文にもこうある。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/fb73e45e-e745-4de7-bee0-3afab3b578a1_20220501005730_1.jpg?w=1116&h=627",
          "alt": null
        }}></img></p>
      <blockquote>
        <p parentName="blockquote">{`第二次ローデイル防衛戦`}<br parentName="p"></br>{`
`}{`忌み鬼、英雄の屍を築く`}<br parentName="p"></br>{`
`}{`黄金樹に揺らぎなし`}</p>
      </blockquote>
      <p>{`また、以下のゲームシステムの挙動からも、マルギット＝モーゴットであることは大分確からしいことが伺える。`}</p>
      <ul>
        <li parentName="ul">{`マルギットを倒さずにモーゴットを倒すと、マルギット撃破のトロフィーがもらえる（らしい）`}</li>
        <li parentName="ul">{`アイテム「マルギットの拘束具」はモーゴットにも有効`}</li>
      </ul>
      <p>{`……と、そんな感じで、忌み子でありながらもローデイルを統治し、一方ではこそっと出張してエルデの王になろうとする褪せ人やら、ローデイルに攻めてくる勢力を片付けていたのがモーゴットなはず。`}</p>
      <p>{`ちなみに、王家の忌み子であるモーゴットが、どうやって地下から抜けたのかみたいな過去の経緯は不明。ただ、その時に使われていたであろうアイテムが以下。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/a405145e-b290-43a5-bc52-fd5c98744635_20220501005042_1.jpg?w=1116&h=627",
          "alt": null
        }}></img></p>
      <blockquote>
        <p parentName="blockquote">{`黄金の魔力を帯びた呪物`}<br parentName="p"></br>{`
`}{`忌み子と呼ばれる呪われた者たち`}<br parentName="p"></br>{`
`}{`そのただ一人を、特に厳重に拘束するもの`}</p>
        <p parentName="blockquote">{`僅かだが、その拘束の魔力は残っており`}<br parentName="p"></br>{`
`}{`かつての幽囚、マルギットを`}<br parentName="p"></br>{`
`}{`一時的に地に縛るだろう`}</p>
      </blockquote>
      <p>{`もしかしたら、モーゴットと名乗ったのは、地下から出た後だったのかもしれない。`}</p>
      <p>{`まぁそんなこんなで、忌み子だったんだけど色々頑張っていたんですな。`}</p>
      <h2 {...{
        "id": "戦闘前後の会話"
      }}>{`戦闘前後の会話`}</h2>
      <p>{`そしてモーゴットと戦う前に言われることがこれ。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/d249976f-80ab-4644-bae7-5b6504bdf4f9_ss_2022-05-01_21.23.01.png?w=1116&h=627",
          "alt": null
        }}></img></p>
      <blockquote>
        <p parentName="blockquote">{`祝福なき褪せ人よ`}<br parentName="p"></br>{`
`}{`王の座に、何の用がある`}</p>
        <p parentName="blockquote">{`ああ・・・`}<br parentName="p"></br>{`
`}{`黄金のゴドリック`}<br parentName="p"></br>{`
`}{`天賦の双子、ミケラとマレニア`}<br parentName="p"></br>{`
`}{`将軍ラダーン`}<br parentName="p"></br>{`
`}{`法務官ライカード`}<br parentName="p"></br>{`
`}{`月の王女、ラニ`}<br parentName="p"></br>{`
`}{`まつろわぬ、裏切り者共`}</p>
        <p parentName="blockquote">{`お前たちは、皆、同じ`}<br parentName="p"></br>{`
`}{`野心の火に焼かれた、略奪者よ`}<br parentName="p"></br>{`
`}{`・・・愚かな墓標に刻むがよい`}<br parentName="p"></br>{`
`}{`最後の王、モーゴットの名を！`}</p>
      </blockquote>
      <p>{`ここまでに書いてきたことを考慮すると、ここでモーゴットが何を考えてこのようなセリフを吐いたのかが、色々と想像できる。`}</p>
      <p>{`他のデミゴッドは、だいたい好き勝手してる。破砕戦争で争い、ライカードはなんか蛇に食べられてるし、ラニは魂だけどっかいっちゃうし、ミケラは聖樹と一体化しようとするし、なんなの？ 黄金樹放っといてどういうつもりなわけ？ 俺が最後の王だ！ という感じが伝わるんじゃないだろうか。`}</p>
      <p>{`そして戦いの中で、なんか呪いの血を吹き出してしまうんだけれども、そこでも、`}</p>
      <blockquote>
        <p parentName="blockquote">{`・・・王の座を、呪いで穢すなど・・・`}<br parentName="p"></br>{`
`}{`・・・耐えがたい恥よ・・・`}<br parentName="p"></br>{`
`}{`・・・許さんぞ、お前だけは・・・`}</p>
      </blockquote>
      <p>{`と言う。`}</p>
      <p>{`これは、そうやって黄金樹を守るとするものの、やはり自身の血は呪われている穢れた存在であることも受け入れていることが伺える。マジメだ。そして悲しい。`}</p>
      <p>{`戦闘に勝利すると、`}</p>
      <blockquote>
        <p parentName="blockquote">{`・・・褪せ人よ、お前は愚かだ・・・`}<br parentName="p"></br>{`
`}{`・・・黄金樹は、すべてを拒んでいる`}<br parentName="p"></br>{`
`}{`我らは、見捨てられたのだ`}</p>
        <p parentName="blockquote">{`・・・もう誰も、エルデの王にはなれぬのだ・・・`}<br parentName="p"></br>{`
`}{`・・・私と、同じようにな・・・`}</p>
      </blockquote>
      <p>{`と言うが、そんな風に黄金樹を守ろうとしても、黄金樹には拒まれ、エルデの王にはなれなかった悲しさがにじみ出た一言であろう。`}</p>
      <h2 {...{
        "id": "忌み王の記憶"
      }}>{`忌み王の記憶`}</h2>
      <p>{`そんなモーゴットを倒して得られる追憶のテキスト`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/68237bcf-9686-401b-9954-9c44a0f7e379_20220428230711_1.jpg?w=1116&h=627",
          "alt": null
        }}></img></p>
      <blockquote>
        <p parentName="blockquote">{`祝福なき忌み子として生れ落ちてなお`}<br parentName="p"></br>{`
`}{`モーゴットは、黄金樹の守人であろうとした`}<br parentName="p"></br>{`
`}{`愛されたから、愛したのではない`}<br parentName="p"></br>{`
`}{`彼はただ愛したのだ`}</p>
      </blockquote>
      <p>{`愛されたから、愛したのではない`}<br parentName="p"></br>{`
`}{`彼はただ愛したのだ`}</p>
      <p>{`このゲーム内屈指の味わい深いテキストだと思う。`}</p>
      <h2 {...{
        "id": "ゴッドフレイ戦の前の会話"
      }}>{`ゴッドフレイ戦の前の会話`}</h2>
      <p>{`そんなこんなを考慮すると、終盤、ゴッドフレイと戦う前の以下のシーンがなかなか。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/f5dcabcc-97c0-4275-bc06-d0c7e3f316e3_godfrey.png?w=1116&h=753",
          "alt": null
        }}></img></p>
      <blockquote>
        <p parentName="blockquote">{`・・・久しかったな、モーゴットよ`}<br parentName="p"></br>{`
`}{`・・・よくぞ、戦い抜いた`}</p>
      </blockquote>
      <p>{`ゴッドフレイは戦う前、モーゴットの遺体を抱えてこれだけを言う。そして、モーゴットの魂は黄金樹に帰っていく。`}</p>
      <p>{`ここで、ゴッドフレイはモーゴットの父ちゃんなんですよな。モーゴットは忌み子で地下に幽閉されていたので、家庭円満な幼少期を送っているはずがない（そもそもそういう概念があったりするのか謎だけど、レナラとラニにはそのような関係があったことが伺える）。一方ゴッドフレイは祝福を奪われて狭間の地を追放された…… ということを考えると、戦い抜いて死したモーゴットが、再会した父に褒められているシーンであるということが分かる。`}</p>
      <p>{`というわけで、これは感動の親子再会シーンなのであった。`}</p>
      <p>{`以降、モーゴットに関係しそうなトピック。`}</p>
      <h2 {...{
        "id": "プレイヤーはゴッドフレイの子孫"
      }}>{`プレイヤーはゴッドフレイの子孫？`}</h2>
      <p>{`マルギットと戦う時、戦闘中に以下のようなことを言われる。`}</p>
      <blockquote>
        <p parentName="blockquote">{`・・・ほう、侮れぬものだな`}<br parentName="p"></br>{`
`}{`やはり褪せ人は、戦士の末裔ということか`}</p>
      </blockquote>
      <p>{`このゲーム内において、狭間の地の外側の情報はほぼ無いので確かなことは言えないが、`}</p>
      <ul>
        <li parentName="ul">{`褪せ人は戦士の末裔`}</li>
        <li parentName="ul">{`最初の褪せ人というのはゴッドフレイ`}</li>
      </ul>
      <p>{`ということを合わせて考えると、プレイヤーの先祖はゴッドフレイという可能性がある。`}</p>
      <p>{`狭間の地の黄金律ルールだと死が取り除かれているので、時間の流れ的にどのくらいの時間が経っているのかは謎。ただ、プレイヤーの祖先がゴッドフレイだとすると、モーゴットやゴッドフレイと戦うのは一族内の争いということになり、親を越えていくような話とも捉えられる。`}</p>
      <h2 {...{
        "id": "忌み子と坩堝"
      }}>{`忌み子と坩堝`}</h2>
      <p>{`角が生えてしまう忌み子という存在について、その詳細は詳しく語られないが、以下のアイテムのテキストからすると、それは狭間の地の生命のあり方故にそうなったという可能性がある。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/d3dc8aad-9bdf-4750-98e9-5558020d270b_20220429004009_1.jpg?w=1116&h=627",
          "alt": null
        }}></img></p>
      <blockquote>
        <p parentName="blockquote">{`古い黄金樹の祈祷のひとつ`}</p>
        <p parentName="blockquote">{`しなやかな尾を尻に生じ、前方を薙ぎ払う`}<br parentName="p"></br>{`
`}{`タメ使用で強化される`}</p>
        <p parentName="blockquote">{`それは、黄金樹の原初たる生命の力`}<br parentName="p"></br>{`
`}{`坩堝の諸相のひとつである`}<br parentName="p"></br>{`
`}{`かつて、生命は混じり合っていた`}</p>
      </blockquote>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/d30444e8-13d5-4c76-9f20-b8060e29469a_20220429020129_1.jpg?w=1116&h=627",
          "alt": null
        }}></img></p>
      <blockquote>
        <p parentName="blockquote">{`古い時代、人の身体に生じたという`}<br parentName="p"></br>{`
`}{`諸相の混ざった鱗のタリスマン`}</p>
        <p parentName="blockquote">{`致命の一撃のダメージを軽減する`}</p>
        <p parentName="blockquote">{`それは、生命の原初たる坩堝の名残である`}<br parentName="p"></br>{`
`}{`部分的な先祖返りであり、古くは神聖視されたが`}<br parentName="p"></br>{`
`}{`文明の後には穢れとして扱われた`}</p>
      </blockquote>
      <p>{`我々の世界観からすると、忌み子に対する扱いというのは、差別の一種のような存在に思える。`}</p>
      <h2 {...{
        "id": "夜の騎兵とマルギット"
      }}>{`夜の騎兵とマルギット`}</h2>
      <p>{`夜の騎兵はマルギットの部下であった旨が、「夜騎兵の兜」をはじめとするアイテムのテキストから伺える。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/48263669-f6e7-4c10-a802-9f5ce5955dce_20220501011129_1.jpg?w=1116&h=627",
          "alt": null
        }}></img></p>
      <blockquote>
        <p parentName="blockquote">{`夜の街道をさまよう騎兵たちは`}<br parentName="p"></br>{`
`}{`かつては、忌み鬼に率いられた`}<br parentName="p"></br>{`
`}{`あらゆる戦士、騎士、そして英雄の死神である`}</p>
      </blockquote>
      <p>{`「かつては」ということは今はそうでは無さそう。`}</p>
    </Body>
    <Outro mdxType="Outro">
      <p>{`以上、マルギットについてまとめました。`}</p>
      <p>{`愛されたから、愛したのではない`}<br parentName="p"></br>{`
`}{`彼はただ愛したのだ`}</p>
      <p>{`いいですね。`}</p>
    </Outro>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      